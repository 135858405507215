import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateIsRuleExpanded } from "@aptedge/lib-ui/src/redux/reduxSlice/aptAnswerSlice";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import { FC, useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../../clients/cache";
import { AptAnswersErrorMessage } from "../../../components/AptAnswers/AptAnswersErrorMessage";
import { AptAnswersFilters } from "../../../components/AptAnswers/AptAnswersFilters";
import { UnsavedModal } from "../../../components/AptAnswers/UnsavedModal";
import { getAptAnswersFilterInfo } from "../../../components/AptAnswers/utils/getAptAnswersFilterInfo";
import { getFilterRulesForPayload } from "../../../components/AptAnswers/utils/getFilterRulesForPayload";
import { mergeFiltersWithIntegrations } from "../../../components/AptAnswers/utils/mergeFiltersWithIntegrations";
import { useAptAnswersFilters } from "../../../hooks/useAptAnswersFilters";
import styles from "./HiddenFilters.module.scss";
import { PageHeader } from "./PageHeader";

const HiddenFilters: FC = () => {
  const { isRuleExpanded } = useAppSelector((state) => state.aptAnswer);
  const dispatch = useAppDispatch();
  // Handle basic information for Hidden Filters
  const { filterPurpose, filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.HIDDEN_FILTER
  );
  const filterName = AptAnswersFilterType.HIDDEN_FILTER;

  // Get integration and filter data
  const {
    isError,
    isLoading,
    isSuccess,
    filtersData,
    integrationsData
  } = useAptAnswersFilters(filterType);

  // Merge integration and filter data
  const integrations = useMemo(
    () =>
      mergeFiltersWithIntegrations({
        filterName,
        filtersData,
        integrationsData,
        filterPurpose
      }),
    [filterName, filtersData, integrationsData, filterPurpose]
  );

  const globalResultsRef = useRef<HTMLDivElement>(null);
  const [integrationValues, setIntegrationValues] = useState(integrations);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasCustomFieldsToggled, setHasCustomFieldsToggled] = useState(false);

  useEffect(() => {
    // filtersData can be {}, which will always cause a re-render in this useEffect
    // Even useMemo doesn't work, so we check to make sure that filtersData is
    // not an empty object before updating
    if (integrations && Object.keys(filtersData).length !== 0) {
      setIntegrationValues(integrations);
    }
  }, [integrations, filtersData, setIntegrationValues]);

  useEffect(() => {
    dispatch(updateIsRuleExpanded({}));
  }, [dispatch]);

  // Define mutations
  const queryClient = useQueryClient();
  const resetOnMutation = useCallback(() => {
    queryClient.invalidateQueries([
      WebCacheKey.APT_ANSWERS_FILTERS,
      filterType
    ]);
    setIntegrationValues(integrations);
  }, [queryClient, filterType, integrations]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      handleRuleCollapse();
      resetOnMutation();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      handleRuleCollapse();
      resetOnMutation();
    }
  });

  // Execute mutations
  const handleSave = (): void => {
    const payload = integrationValues
      .map((integration) => {
        const filterRules = getFilterRulesForPayload({
          filterPurpose,
          integration
        });
        return {
          ...(integration?.filter?.id && { id: integration?.filter?.id }),
          name: filterName,
          filterType,
          filterRules
        };
      })
      .filter((integration) => {
        return integration.filterRules.length !== 0 || integration.id;
      });
    const isUpdate = payload.some((integration) => integration.id);
    if (payload.length === 0) return;
    if (isUpdate) {
      updateFilterMutation.mutate({ filters: payload });
    } else {
      createFilterMutation.mutate({ filters: payload });
    }
  };

  const handleRuleCollapse = (): void => {
    const newRuleExpanded = { ...isRuleExpanded };
    Object.keys(newRuleExpanded).map((rule) => (newRuleExpanded[rule] = false));
    dispatch(updateIsRuleExpanded(newRuleExpanded));
  };

  const handleRedirection = (): void => {
    if (hasCustomFieldsToggled) {
      setShowConfirmationModal(true);
    }
  };

  useOnMouseDownOutside(globalResultsRef, handleRedirection);

  return (
    <div className={styles.container} ref={globalResultsRef}>
      <PageHeader
        title="Global filters"
        subtitle="Customize the knowledge used in answers and search results"
      />
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There was an error loading Hidden Results." />
      )}
      {isSuccess && (
        <AptAnswersFilters
          filterType={filterType}
          integrations={integrationValues}
          setIntegrationValues={setIntegrationValues}
          handleItemSave={handleSave}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleRuleCollapse={handleRuleCollapse}
        />
      )}
      {showConfirmationModal && (
        <UnsavedModal
          reset={resetOnMutation}
          setHasCustomFieldsToggled={setHasCustomFieldsToggled}
          setShowConfirmationModal={setShowConfirmationModal}
          handleSave={handleSave}
          integrations={integrationValues}
          handleRuleCollapse={handleRuleCollapse}
        />
      )}
    </div>
  );
};

export { HiddenFilters };
